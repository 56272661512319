<template>
  <draggable tag="div"
             v-model="listItems"
             :animation="200"
             ghostClass="ghost"
             @start="isDragging = true"
             @end="isDragging = false">
    <transition-group type="transition" name="list-flip">
      <v-row v-for="item in listItems"
             :key="item.id"
             v-bind="$attrs"
             v-on="$listeners"
             class="text--secondary pr-1 cursor--move list-item"
             :class="listItemClass">
        <v-col cols="auto" v-if="hasHandle">
          <v-icon>
            {{ iconHandle }}
          </v-icon>
        </v-col>
        <v-col>
          {{ item.text }}
        </v-col>
        <slot name="suffix">
          <v-col cols="auto" v-if="canDelete" @click="deleteItem({list: listItems, item})">
            <v-btn icon small>
              <v-icon>
                {{ iconDelete }}
              </v-icon>
            </v-btn>
          </v-col>
        </slot>
      </v-row>
    </transition-group>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'KListDraggable',
  inheritAttrs: false,
  components: {
    draggable,
  },
  data() {
    return {
      isDragging: false,
    };
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    iconDelete: {
      type: String,
      default: '$close',
    },
    hasHandle: {
      type: Boolean,
      default: true,
    },
    iconHandle: {
      type: String,
      default: '$grip',
    },
    listItemClass: {
      type: String,
    },
  },
  computed: {
    listItems: {
      get() {
        return this.value.map((item, index) => typeof item === 'string' ? ({ text: item, id: index }) : item);
      },
      set(updatedList) {
        this.$emit('input', updatedList);
      },
    },
  },
  methods: {
    deleteItem({ list, item, }) {
      this.$emit('click:delete', { item, list: list.filter(({id}) => id !== item.id), });
    },
  },
};
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-flip-move {
  transition: all 1s;
}

.list-item {
  min-height: 20px
}

.cursor--move {
  cursor: move;
}
</style>
