<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/product/api/productCategory.js';

export default {
  name: 'ProductCategoriesAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  methods: {
    autocomplete(...args) {
      return autocomplete(this.clientId, ...args)
    }
  },
};
</script>
