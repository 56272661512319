<template>
  <k-field-group language-prefix="product.fields">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels">
      <template #panel.0>
        <v-row class="fill-height">
          <v-col cols="6">
            <form-sheet class="fill-height">
              <ProductCategoriesAutocomplete v-model="values.categories"
                                             :client-id="clientId"
                                             field="category"
                                             :return-object="false"
                                             multiple
                                             required/>
              <KTextField v-model="values.name" field="name" required/>
              <KTextField v-model="values.sku" field="sku" required/>
              <KFigureField v-model="values.price"
                            field="salesPrice"
                            required
                            :suffix="$t('product.fields.currency')"
                            grid="pr-2"
                            min="0"
                            step=".01"/>
              <KTextField v-model="values.dimensions" field="dimensions" required/>
              <KTextarea v-model="values.description" field="description" required/>
            </form-sheet>
          </v-col>
          <v-col cols="6">
            <form-sheet class="fill-height">
              <KFileField v-model="values.image" field="image" required/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
      <template #panel.1>
        <v-row class="fill-height">
          <v-col cols="6">
            <form-sheet class="fill-height">
              <KFigureField v-model="values.minRequiredAddons"
                            field="minRequiredAddons"
                            required
                            grid="pr-2"
                            min="0"
                            step="1"/>
              <ProductAutocomplete field="itemCodeProductName" v-model="selectedAddon"/>
              <v-row no-gutters justify="end">
                <v-btn color="primary" depressed tile :disabled="!canAddAddon" @click="addAddon">
                  {{ $t('product.misc.addAddon') }}
                </v-btn>
              </v-row>
              <div v-if="values.addons.length === 0" class="text--secondary text-caption font-italic my-6">
                {{ $t('product.misc.noAddons') }}
              </div>
              <KListDraggable v-else v-model="values.addons" list-item-class="my-1" @click:delete="removeAddon"/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import EventBus from '@/application/eventBus.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KListDraggable from '@/components/crud/fields/KListDraggable.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import FormSheet from '@/components/FormSheet.vue';
import ProductAutocomplete from '@/modules/product/components/ProductAutocomplete.vue';
import ProductCategoriesAutocomplete from '@/modules/product/components/ProductCategoriesAutocomplete.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductForm',
  components: {
    ProductAutocomplete,
    KListDraggable,
    ProductCategoriesAutocomplete,
    KFigureField,
    KTextarea,
    FormSheet,
    KFileField,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  data() {
    return {
      selectedAddon: null,
    };
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('settings', { clientId: 'getCurrentClientId' }),
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
        { name: this.$t('product.tabs.addons') },
      ];
    },
    canAddAddon() {
      if (!this.selectedAddon?.id || this.selectedAddon?.id === this.values.id) return false;
      const alreadyPresent = Array.isArray(this.values.addons) && this.values.addons.find(({ id }) => id === this.selectedAddon.id);
      return !alreadyPresent;
    },
  },
  methods: {
    addAddon() {
      const {
        name,
        id,
      } = this.selectedAddon;
      this.values.addons.push({
        id,
        text: name,
      });
    },
    removeAddon({
      list,
      item,
    }) {
      EventBus.$emit('snackbar', {
        color: 'success',
        text: this.$t('actions.resourceRemoved', { resource: `"${item.text}"` }),
      });
      this.values.addons = list;
    },
  },
};
</script>
