<template>
  <div>
    <k-crud-layout :search.sync="searchQuery" :filters.sync="filters">
      <template #header>{{ $t('product.pageTitle') }}</template>
      <template #flow-actions>
        <v-btn color="primary" depressed tile outlined @click="handleOpenImportDialog" class="mr-3">
          {{ $tc('product.import') }}
        </v-btn>
        <v-btn color="primary" depressed tile @click="handleOpenCreateDialog">
          {{ $t('product.create') }}
        </v-btn>
        <ProductForm v-model="createDialog"
                     :request="createRequest"
                     :title="$t('product.create')"
                     :values="createFormValues"
                     @change="$refs.table.reload()"/>
        <ImportForm v-model="importDialog"
                    ref="importDialog"
                    :title="$t('product.import')"
                    @change="$refs.table.reload()"/>
      </template>
      <template #filters="{attrs, on}">
        <ProductsFilter
            v-bind="attrs"
            v-on="on"
        />
      </template>
      <template #view.list>
        <k-crud-table ref="table"
                      :headers="crudHeaders"
                      :index-request="indexRequest"
                      :search="searchQuery"
                      :params="filters"
                      language-prefix="product.fields"
                      resource="product"
                      @click:edit="openUpdate"
                      @click:row="openUpdate">
          <template #item.categories="{item: {categories}}">
            {{ [...categories].map(category => 'name' in { ...category } ? category.name : '').join(', ') }}
          </template>
          <template #item.price="{item:{price}}">
            {{ $n(price, 'currency') }}
          </template>
        </k-crud-table>
      </template>

      <!-- @TODO: template category and price -->

    </k-crud-layout>

    <ProductForm v-model="updateDialog"
                 :request="updateRequest"
                 :title="$t('product.update')"
                 :values="updateFormValues"
                 is-update-form
                 @change="$refs.table.reload()"/>
  </div>
</template>

<script lang="js">
import eventBus from '@/application/eventBus.js';
import Product from '@/application/models/Product.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/product/api';
import ProductForm from '@/modules/product/components/ProductForm.vue';
import ImportForm from '@/modules/product/components/ImportForm.vue';
import { mapGetters } from 'vuex';
import ProductsFilter from '@/modules/product/components/ProductsFilter.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';

export default {
  name: 'ProductResource',
  mixins: [querySyncMixin],
  components: {
    ProductsFilter,
    ProductForm,
    KCrudTable,
    KCrudLayout,
    ImportForm,
  },
  data() {
    return {
      filters: {},
      searchQuery: '',
      createDialog: false,
      importDialog: false,
      createFormValues: new Product(),
      dialogOpen: false,
      updateDialog: false,
      updateFormValues: new Product(),
    };
  },

  computed: {
    ...mapGetters('settings', { currentClientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'sku',
          language: 'sku',
        },
        {
          value: 'price',
          language: 'salesPrice',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'product.index' },
        text: this.$t('product.pageTitle'),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new Product();
      this.createDialog = true;
    },
    handleOpenImportDialog() {
      this.$refs.importDialog.reset();
      this.importDialog = true;
    },
    createRequest() {
      return create(this.currentClientId, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    async indexRequest(...args) {
      const result = await index(this.currentClientId, ...args);
      result.data.data = result.data.data.map(product => new Product().mapResponse(product));
      return result;
    },
    async openUpdate(item) {
      this.updateFormValues = new Product();
      const showRequest = await show(item.id);
      this.updateFormValues.mapResponse(showRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
