import Model from './model';

class Product extends Model {
  id = 0;
  name = '';
  sku = '';
  price = 0;
  description = '';
  dimensions = '';
  categories = [];
  image = null;
  minRequiredAddons = 0;
  addons = [];

  mapForRequest() {
    return {
      name: this.name,
      sku: this.sku,
      price: parseInt(this.price * 100),
      description: this.description,
      dimensions: this.dimensions,
      categories: Array.isArray(this.categories) ? this.categories : [this.categories],
      image: this.image instanceof Blob ? this.image : typeof this.image === 'undefined' ? null : undefined,
      minRequiredAddons: this.minRequiredAddons,
      'add-ons': this.addons.length > 0 ? this.addons.map((product, index) => ({
        id: product.id,
        order: index,
      })) : null,
    };
  }

  mapResponse(data) {
    if (data?.categories) data.categories = data.categories.map(({ id }) => id);
    data.price = data.price / 100;
    const getAddons = data?.['add-ons'] || [];
    data.addons = getAddons.map(addon => ({
        ...addon,
        text: addon.name,
      }));
    return super.mapResponse(data);
  }
}

export default Product;
