<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import store from '@/application/store';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/product/api';

export default {
  name: 'ProductAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete(...args) {
      return autocomplete(store.getters['settings/getCurrentClientId'], ...args);
    },
  },
};
</script>
