<template>
  <div :class="this.grid" class="k-field-wrapper">
    <VTextField v-model="numberValue"
                v-bind="fieldProps"
                :label="translatedLabel"
                :rules="computedRules"
                :step="step"
                type="number"/>
  </div>
</template>

<script>
import FieldMixin from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'KFigureField',
  inheritAttrs: false,
  mixins: [FieldMixin],
  data() {
    return {
      numberValue: null,
    };
  },
  props: {
    value: {
      type: [Number, String],
    },
    step: {
      type: String,
      default: '.1',
    },
  },
  watch: {
    numberValue(value) {
      this.$emit('input', value === null ? value : this.floatDecimals(value));
    },
  },
  created() {
    this.numberValue = this.value;
  },
  methods: {
    floatDecimals(numberInput) {
      let decimals = 0;
      if ((Number.parseFloat(this.step) % 1) !== 0) decimals = this.step.toString().split('.')[1].length;
      if (decimals === 0) return Number.parseInt(numberInput)
      return Number.parseFloat(numberInput).toFixed(decimals);
    },
  },
};
</script>
