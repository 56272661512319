import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/product`, page, perPage, search, sortBy, descending, params);

export const autocomplete = (clientId, ...args) =>
  getPaginated(`client/${clientId}/product/autocomplete`, ...args);

export const show = (id) => get(`product/${id}`);

export const create = (clientId, values) => post(`client/${clientId}/product`, values);

export const update = (values) => put(`product/${values.id}`, values);

export const getImportTemplate = (clientId) => get(`client/${clientId}/product/export`);

export const importProduct = (clientId, file) => {
  console.log(file)
  return post(`client/${clientId}/product/csv-import`, file);
};
