var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-layout',{attrs:{"search":_vm.searchQuery,"filters":_vm.filters},on:{"update:search":function($event){_vm.searchQuery=$event},"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.$t('product.pageTitle')))]},proxy:true},{key:"flow-actions",fn:function(){return [_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":"","tile":"","outlined":""},on:{"click":_vm.handleOpenImportDialog}},[_vm._v(" "+_vm._s(_vm.$tc('product.import'))+" ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","tile":""},on:{"click":_vm.handleOpenCreateDialog}},[_vm._v(" "+_vm._s(_vm.$t('product.create'))+" ")]),_c('ProductForm',{attrs:{"request":_vm.createRequest,"title":_vm.$t('product.create'),"values":_vm.createFormValues},on:{"change":function($event){return _vm.$refs.table.reload()}},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}}),_c('ImportForm',{ref:"importDialog",attrs:{"title":_vm.$t('product.import')},on:{"change":function($event){return _vm.$refs.table.reload()}},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}})]},proxy:true},{key:"filters",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('ProductsFilter',_vm._g(_vm._b({},'ProductsFilter',attrs,false),on))]}},{key:"view.list",fn:function(){return [_c('k-crud-table',{ref:"table",attrs:{"headers":_vm.crudHeaders,"index-request":_vm.indexRequest,"search":_vm.searchQuery,"params":_vm.filters,"language-prefix":"product.fields","resource":"product"},on:{"click:edit":_vm.openUpdate,"click:row":_vm.openUpdate},scopedSlots:_vm._u([{key:"item.categories",fn:function(ref){
var categories = ref.item.categories;
return [_vm._v(" "+_vm._s([].concat( categories ).map(function (category) { return 'name' in Object.assign({}, category) ? category.name : ''; }).join(', '))+" ")]}},{key:"item.price",fn:function(ref){
var price = ref.item.price;
return [_vm._v(" "+_vm._s(_vm.$n(price, 'currency'))+" ")]}}])})]},proxy:true}])}),_c('ProductForm',{attrs:{"request":_vm.updateRequest,"title":_vm.$t('product.update'),"values":_vm.updateFormValues,"is-update-form":""},on:{"change":function($event){return _vm.$refs.table.reload()}},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }