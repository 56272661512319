<template>
  <k-field-group language-prefix="product.filterFields">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('actions.applyFilters')"
               :title="$t('product.filterTitle')"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">

      <KFigureField v-model="form.priceFrom"
                    field="priceFrom"
                    hide-details
                    step="0.01"
                    class="mb-2 pb-1"/>
      <KFigureField v-model="form.priceTo"
                    field="priceTo"
                    hide-details
                    class="mb-2 pb-1"/>
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';

export default {
  name: 'ProductsFilter',
  inheritAttrs: false,
  components: {
    KFigureField,
    KSidebar,
    KFieldGroup,
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      priceFrom: null,
      priceTo: null,
    },
  }),
  watch: {
    filters: {
      handler(values) {
        values = {
          priceFrom: values.priceFrom ? values.priceFrom / 100 : undefined,
          priceTo: values.priceTo ? values.priceTo / 100 : undefined,
        };
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.cleanFilters(this.form);
      this.form = {
        priceFrom: this.form.priceFrom ? this.form.priceFrom * 100 : undefined,
        priceTo: this.form.priceTo ? this.form.priceTo * 100 : undefined,
      };
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    cleanFilters(obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || !Number(obj[propName])) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
